<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Book Vendor Type</span>
            </v-card-title>
            <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                                <label class="add-text">Add New Book Vendor Type</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="vendorType" outlined dense :rules="[rules.required]"
                                    clearable required></v-text-field>
                                <label class="add-text">Book Vendor Type Description</label>
                                <v-text-field class="text" v-model="description" outlined dense 
                                    clearable ></v-text-field>
                                <label class="add-text">Add Display Order</label><span style="color: red;"> *</span>
                                <v-text-field class="text" type="number" v-model="displayorder" outlined :rules="[rules.required]" required
                                    dense></v-text-field>
                                <label class="add-text">Add Short Name</label>
                                <v-text-field class="text" v-model="shortname" outlined dense></v-text-field>
                                <v-btn color="success" @click="addVendorType">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Vendor Type
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Book Vendor Type</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="vendorType" outlined dense :rules="[rules.required]"
                                        clearable required></v-text-field>
                                    <label class="add-text"> Vendor Type Description</label>
                                    <v-text-field class="text" v-model="description" outlined dense clearable
                                        required></v-text-field>
                                    <label class="add-text">Add Display Order</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" type="number" v-model="displayorder" outlined
                                        dense></v-text-field>
                                    <label class="add-text">Add Short Name</label>
                                    <v-text-field class="text" v-model="shortname" outlined dense></v-text-field>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>
                <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
                <template v-if="vendorType_list != null">
                    <v-data-table :headers="headers" :items="vendorType_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updateBookVendorType(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteBookVendorType(item)"> mdi mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </template>
                </div>
            </v-card-text>
            </div>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        init_loading: false,
        loader: false,
        snackbar_msg: "",
        snackbar: false,
        color: "",
        org: null,
        dialog: false,
        search: "",
        vendorType: null,
        vendorTypeId: null,
        vendorTypeName: null,
        edit_btn_load: false,
        shortname: null,
        description: null,
        displayorder: null,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Book Vendor Type',
                disabled: true,
                href: '',
            },
        ],

        rules: {
            required: (value) => !!value || "Required.",
        },
        bookType_list: null,
        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Vendor Type", value: "vendorType" },
            { text: "Description", value: "description" },
            { text: "Short Name", value: "shortname" },
            { text: "Edit", value: "edit" },
            { text: "Delete", value: "delete" },
        ],
    }),
    watch: {},
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        updateBookVendorType(item) {
            console.log(item);
            this.dialog = true;
            this.vendorType = item.vendorType;
            this.vendorTypeId = item.vendorTypeId;
            this.description = item.description;
            this.displayorder = item.displayorder;
            this.shortname = item.shortname;
        },

        saveEdit(item) {
            if(!this.vendorType){
                this.showSnackbar("#b71c1c", "Please Enter Vendor Type");
                return;
            }
            else if(!this.displayorder){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }
            const data = {
                vendorType: this.vendorType,
                vendorTypeId: this.vendorTypeId,
                displayorder: this.displayorder,
                shortname: this.shortname,
                description: this.description
            };
                this.loader  = true;
                axios
                    .post("/admin/editVendorType", data)
                    .then((res) => {
                        if (res.data.msg == "success") {
                            this.loader = false;
                            // this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Vendor Type updated successfully...");
                            this.onload();
                            this.dialog = false;
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.loader = false;
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.loader = false;
                        this.edit_btn_load = false;
                        // this.editUserType = null;
                        axios;
                    });
           
        },
        deleteBookVendorType(item) {
            const data = {
                vendorTypeId: item.vendorTypeId,
            };
            axios
                .post("/admin/deleteVendorType", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Vendor Type Deleted Successfully  !!");
                        this.onload();
                    } else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },

        onload() {
            this.init_loading = true
            axios
                .post("/admin/getVendorType")
                .then((res) => {
                    this.org = res.data.org;
                    this.vendorType_list = res.data.vendorType;
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        addVendorType() {
            if (this.vendorType) {
                this.vendorType = this.vendorType.trim()
            }

            if(!this.vendorType){
                this.showSnackbar("#b71c1c", "Please Enter Vendor Type");
                return;
            }
            else if(!this.displayorder){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }

            const data = {
                vendorType: this.vendorType,
                displayorder: this.displayorder,
                shortname: this.shortname,
                description: this.description
            };

                this.loader = true;
                axios
                    .post("/admin/addVendorType", data)
                    .then((res) => {
                        this.org = res.data.org;
                        this.bookType_list = res.data.bookType_list;
                        if (res.data.msg == "success") {
                            this.loader  =false;
                            this.showSnackbar("#4CAF50", "Vendor Type added successfully...");
                            this.onload();
                            // this.userType = null;
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                            this.$refs.form.validate()
                        }
                    })
                    .catch((error) => {
                        this.loader = false;
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.loader = false;
                     });
        
        },
    },
    mounted() {
        this.onload();
    },
};
</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
